import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue' 
import Success from '@/views/success/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/branch',
    name: 'home',
    component: Home
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  }

]

const router = new VueRouter({
  routes
})

export default router
