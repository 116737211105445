<template>
    <div class="form">
        <div class="steps" v-if="state != 0">
            <el-steps :space="200" :active="active" :align-center="true">
                <el-step title="身份验证"></el-step>
                <el-step title="删除范围"></el-step>
                <el-step title="确认提交"></el-step>
            </el-steps>
        </div>
        <!-- 0 -->
        <div class="zero" v-if="state === 0">
            <p>删除应用帐号和相关数据请求</p>
            <p>
                在这个页面，您可以请求删除您在我们应用上的帐号及相关数据。请注意，此操作将永久删除您的帐号和与之相关的所有数据，包括但不限于个人信息、设置和历史记录。请仔细阅读以下步骤，确保您理解此过程。
            </p>
        </div>

        <!-- 1 -->
        <div class="one" v-if="state === 1">
            <p>步骤 1：身份验证</p>
            <p>
                请提供您的帐号登录信息，以验证您的身份。我们需要确保请求删除操作是由帐号的合法所有者提交的。
            </p>
            <el-radio-group v-model="labelPosition" size="small">
                <el-radio-button label="left">手机号</el-radio-button>
                <el-radio-button label="right">邮箱</el-radio-button>
            </el-radio-group>
            <!-- 邮箱 -->
            <div class="one_form" v-if="labelPosition === 'right'">
                <div>
                    <div>邮箱 :</div>
                    <el-input v-model="mailbox"></el-input>
                </div>
                <div>
                    <div>密码 :</div>
                    <el-input v-model="password" show-password></el-input>
                </div>
            </div>
            <!-- 手机号 -->
            <div class="one_form" v-if="labelPosition === 'left'">
                <div>
                    <div>地区编码 :</div>
                    <el-input v-model="area"></el-input>
                </div>
                <div>
                    <div>手机号 :</div>
                    <el-input v-model="phone"></el-input>
                </div>
                <div>
                    <div>密码 :</div>
                    <el-input v-model="password" show-password></el-input>
                </div>
            </div>
        </div>

        <!-- 2 -->
        <div class="two" v-if="state === 2">
            <p>步骤 2：选择删除范围</p>
            <p>在此步骤中，您可以选择要删除的数据范围。您可以选择以下选项：</p>
            <p>
                仅删除应用帐号：选择此选项，只会删除您在我们应用上的帐号，但与之相关的数据将保留。<br />
                删除应用帐号及相关数据：选择此选项，将删除您在我们应用上的帐号以及与之相关的所有数据。这包括您在我们应用上的所有设置、历史记录和个人信息。此操作不可撤销，请谨慎选择。
            </p>
            <div class="two_form">
                <div>
                    <el-radio v-model="type" label="1">
                        仅删除应用帐号
                    </el-radio>
                </div>
                <div>
                    <el-radio v-model="type" label="2">
                        删除应用帐号及相关数据
                    </el-radio>
                </div>
            </div>
        </div>

        <!-- 3 -->
        <div class="three" v-if="state === 3">
            <p>步骤 3：提交删除请求</p>
            <p>
                一旦您确认了删除范围，点击下方的"提交删除请求"按钮，我们将开始处理您的请求。
            </p>
            <div class="three_form">
                <div class="three_App">
                    <p>
                        删除帐号和数据是不可逆的操作，请谨慎决定。删除操作可能需要一些时间来完成，请耐心等待。删除后，您将无法恢复您的帐号和数据，所有相关信息将永久丢失。删除操作可能影响您在应用上的服务和体验，包括您的历史记录、设置和个性化内容。联系我们：如果您在删除帐号和数据的过程中遇到问题或需要进一步协助，请联系我们的客服团队：客服邮箱：
                        xiaonvhai0826@163.com
                        免责声明：我们将尽力保护您的个人数据和隐私，但我们不能对第三方可能非法获取或使用数据承担责任。在提交删除请求之前，请确保您已阅读并理解我们的隐私政策和服务条款。
                    </p>
                    <el-checkbox v-model="checked" @change="change">
                        请勾选隐私政策和服务条款
                    </el-checkbox>
                </div>
            </div>
        </div>

        <!-- 下一步/提交 -->
        <div class="el_button">
            <el-button type="primary" v-if="state !== 0" @click="back">
                上一步
            </el-button>
            <el-button type="primary" @click="next(state)">
                {{ state != 3 ? '下一步' : '提交' }}
            </el-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            state: 0,
            active: 0,
            labelPosition: 'left',
            mailbox: '', //邮箱账号
            phone: '', //电话账号
            password: '', //密码
            area: '', //地区编码
            type: '1', //type 类型
            checked: true,
            text: '删除帐号和数据是不可逆的操作，请谨慎决定。删除操作可能需要一些时间来完成，请耐心等待。删除后，您将无法恢复您的帐号和数据，所有相关信息将永久丢失。删除操作可能影响您在应用上的服务和体验，包括您的历史记录、设置和个性化内容。联系我们：如果您在删除帐号和数据的过程中遇到问题或需要进一步协助，请联系我们的客服团队：客服邮箱：xiaonvhai0826@163.com免责声明：我们将尽力保护您的个人数据和隐私，但我们不能对第三方可能非法获取或使用数据承担责任。在提交删除请求之前，请确保您已阅读并理解我们的隐私政策和服务条款。'
        }
    },
    methods: {
        next(num) {
            switch (num) {
                case 0:
                    this.state++
                    break
                case 1:
                    if (this.labelPosition === 'right') {
                        if (this.mailbox && this.password) {
                            this.state++
                            this.active++
                        } else {
                            this.$notify({
                                title: '警告',
                                message: '信息未输入完整哦 ~',
                                type: 'warning'
                            })
                        }
                    } else if (this.labelPosition === 'left') {
                        if (this.phone && this.password && this.area) {
                            this.state++
                            this.active++
                        } else {
                            this.$notify({
                                title: '警告',
                                message: '信息未输入完整哦 ~',
                                type: 'warning'
                            })
                        }
                    }
                    break
                case 2:
                    // console.log(this.type)
                    this.state++
                    this.active++
                    break
                case 3:
                    if (!this.checked) {
                        this.$notify({
                            title: '警告',
                            message: '请勾选协议 ~',
                            type: 'warning'
                        })
                    } else {
                        if (this.labelPosition === 'left') {
                            // 手机号
                            const datas = {
                                account: this.phone, //电话账号
                                password: this.password, //密码
                                countryCode: this.area, //地区编码
                                type: this.type //type 类型
                            }
                            const that = this

                            axios({
                                method: 'post',
                                url: 'https://szadminforum.xiaonvh.com/m/userAccountDeletion/save',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                data: datas
                            })
                                .then(function (response) {
                                    console.log('111111111111')
                                    console.log(response.data)
                                    if (response.data.code === 10001) {
                                        // 错误提示
                                        that.$notify.error({
                                            title: '错误',
                                            message: response.data.msg
                                        })
                                    } else if (response.data.code === 200) {
                                        // 成功提示
                                        that.$notify({
                                            title: '成功',
                                            message: '删除成功 ~',
                                            type: 'success'
                                        })

                                        that.openNewWindow()
                                    }
                                })
                                .catch(function (error) {
                                    that.$notify.error({
                                        title: '错误',
                                        message: '服务器异常,请稍后再试 ~'
                                    })
                                })
                        } else if (this.labelPosition === 'right') {
                            // 邮箱
                            const datas = {
                                account: this.mailbox, //电话账号
                                password: this.password, //密码
                                type: this.type //type 类型
                            }

                            const that = this

                            axios({
                                method: 'post',
                                url: 'https://szadminforum.xiaonvh.com/m/userAccountDeletion/save',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                data: datas
                            })
                                .then(function (response) {
                                    console.log('111111111111')
                                    console.log(response)
                                    if (response.data.code === 10001) {
                                        // 错误提示
                                        that.$notify.error({
                                            title: '错误',
                                            message: response.data.msg
                                        })
                                    } else if (response.data.code === 200) {
                                        // 成功提示
                                        that.$notify({
                                            title: '成功',
                                            message: '删除成功 ~',
                                            type: 'success'
                                        })

                                        that.openNewWindow()
                                    }
                                })
                                .catch(function (error) {
                                    that.$notify.error({
                                        title: '错误',
                                        message: '服务器异常,请稍后再试 ~'
                                    })
                                })
                        }
                    }
                    break
            }
        },
        back() {
            this.state--
            this.active--
        },
        change() {
            if (this.checked) {
                window.open(
                    'http://website.xiaonvh.com/forumPrivacyProtocol.html',
                    '_blank'
                )
            }
        },
        refreshPage() {
            setTimeout(() => {
                location.reload() // 刷新页面
            }, 3000) // 3秒后刷新
        },
        openNewWindow() {
            const routeUrl = this.$router.resolve({
                name: 'success'
            }).href
            window.open(routeUrl, '_self')
            location.reload() // 刷新页面
        }
    }
}
</script>

<style lang="less" scoped>
.form {
    width: 1000px;
    height: 500px; //
    background-color: #ffffff;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .steps {
        width: 420px;
        margin-top: 1vh;
    }
    .zero {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 600;
        }
    }

    .one {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 600;
        }
        .change {
            margin-top: 2vh;
            font-size: 14px;
            color: rgb(46, 116, 236);
            cursor: pointer;
        }
        .one_form {
            > div {
                display: flex;
                margin-top: 12px;
                > div {
                    width: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .el-input {
                    width: 220px;
                }
            }
        }
    }

    .two {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 600;
            text-align: center;
        }
        .two_form {
            > div {
                display: flex;
                margin-top: 12px;
            }
        }
    }

    .three {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 600;
            text-align: center;
        }
        .three_form {
            > div {
                display: flex;
                margin-top: 12px;
            }
            .three_App {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    font-size: 20px;
                    color: #3e3e3e;
                }
            }
        }
    }

    .el_button {
        width: auto;
        position: absolute;
        bottom: 16px;
    }
}
@media screen and (max-width: 480px) {
    .form {
        height: 560px !important;
    }
}
@media screen and (max-width: 1004px) {
    .form {
        width: 94vw !important;
    }
}
@media screen and (max-width: 800px) {
    .three_App {
        p {
            font-size: 14px !important;
        }
    }
}
@media screen and (max-width: 440px) {
    .form {
        .steps {
            width: 84vw !important;
        }
    }
}
</style>
