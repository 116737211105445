<template>
    <div class="head">
        <div class="center">
            <div class="left">
                <div class="image">
                    <img src="@/assets/index_lo.png" />
                </div>
                <div class="text">深圳市小女孩科技有限公司</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.head {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    .center {
        width: 1085px;
        height: 100%;
        display: flex;
        align-items: center;
        .left {
            display: flex;
            align-items: center;
            .image {
                width: 50px;
                height: 50px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
            .text {
                margin-left: 2vw;
                font-size: 30px;
            }
        }
    }
}
@media screen and (max-width: 1088px) {
    .center {
        width: 780px !important;
        .text {
            font-size: 20px !important;
        }
    }
}
@media screen and (max-width: 400px) {
    .center {
        width: 90vw !important;
        .text {
            font-size: 16px !important;
        }
    }
}
</style>