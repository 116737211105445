<template>
    <div class="home">
        <!-- 头部 -->
        <headVue></headVue>

        <div class="content">
            <FormVue></FormVue>
        </div>

        <!-- 底部 -->
        <bottomVue></bottomVue>
    </div>
</template>

<script>
import headVue from '@/components/head.vue'
import bottomVue from '@/components/bottom.vue'
import FormVue from '@/components/form.vue'

export default {
    components: {
        headVue,
        bottomVue,
        FormVue
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    background-color: #f9f7fe;
    .content {
        width: 100%;
        height: 640px; //后面改
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>