<template>
    <div class="bottom">
        <div>
            <span @click="to(1)">粤ICP备2022141982号-1</span>
            <span @click="to(2)">增值电信业务经营许可证 合字B2-20230136</span>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        to(num) {
            switch (num) {
                case 1:
                    window.open(
                        'https://beian.miit.gov.cn/#/Integrated/index',
                        '_blank'
                    )
                    break
                case 2:
                    window.open(
                        'https://xnh-1259348577.cos.ap-hongkong.myqcloud.com/fc/icplicence/ICP.jpeg',
                        '_blank'
                    )
                    break
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bottom {
    width: 100%;
    height: 100px;
    background-color: #27303f;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
        width: 420px;
        color: #ffffff;
        font-size: 12px;
        > span {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
        }
        > span:hover {
            color: #4a82ed;
        }
    }
}
@media screen and (max-width: 430px) {
    .bottom {
        > div {
            width: 100vw !important;
            display: flex;
            > span {
                padding-left: 3vw;
                padding-right: 3vw;
                flex: 1;
            }
        }
    }
}
</style>