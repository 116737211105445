<template>
    <div class="success">
        <p>提交成功 ~</p>
        <div>
            <img src="@/assets/success.png" />
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.success {
    p {
        text-align: center;
        font-size: 20px;
    }
    div {
        display: flex;
        justify-content: center;
    }
}
</style>